import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as $ from 'jquery';
import { ModalFormWrapper } from '../../../../itrust_common/components/others';
import PaymentForm from './PaymentForm'
import { orderPaymentCreate, orderPaymentEditCancel } from '../../../../redux/actions/order/orderPaymentActions';
import { orderLoad, orderUpdate } from '../../../../redux/actions/order/orderActions';
import { claimFormLoad } from '../../../../redux/actions/order/claimFormActions';
import { addMonths, dateFormat, isDateInRange, numberToCurrency } from '../../../../itrust_common/components/HelperFunctions';
import { appointmentLoad } from '../../../../redux/actions/appointmentActions';
import Alert from '../../../../itrust_common/components/Alert';
import _ from 'lodash';
import { patientLoad } from '../../../../redux/actions/patient/patientActions';
import { withRouter } from 'react-router-dom';
import PaymentInProgress from './devicePayment/PaymentInProgress';
import { visionInsuranceClaimFormLoad } from '../../../../redux/actions/addOns/rpa/vision_insurance/visionInsuranceClaimFormAction';

class FinalizeButtonForm extends Component {

  handlePaymentSubmit(data) {
    const { order, location, orderPayment } = this.props
    if (data.amount == '') {
      data.amount = 0
    }
    if (data.is_payover_time && data.mode_of_payment == 'online' && !data.payover_installments_exist) {
      data = { ...data, payover_time_details: { ...data.payover_time_details, payment_method_id: data.payment_method_id, customer_id: orderPayment.payment?.payment_data?.customer_id } }
      this.handlePayOverSubmit(data)
    }
    else if (data.mode_of_payment === 'link' || data.mode_of_payment === 'device') {
      Alert.warning("You can't finalize order with Payment mode Link or Terminal.")
    }
    else if (data.mode_of_payment === 'online' && !data.payment_method_id) {
      Alert.warning("Please add card to make online payments.")
    }
    else if (order.zero_payment_exist && data.amount == 0) {
      this.handleOrderSubmit({ id: order.id, status: 'finalized' }).then(() => this.handlePaymentEditCancel())
    }
    else {
      new Promise(() => {
        this.props.dispatch(orderPaymentCreate(order.id, data))
          .then(() => {
            this.props.dispatch(orderLoad(order.id, { include: '*' }))
            if (order.prior_authorization_number && order.prior_authorization_number.length > 0)
              // this.props.dispatch(claimFormLoad(order.id))
              this.handleClaimFormLoad(order.id)
            if (order.appointment_id) this.props.dispatch(appointmentLoad(order.appointment_id, { include: ['patient', 'staff', 'procedures', 'examination', 'order'] }))
            if (location.pathname.slice(0, 10) === '/patients/') {
              // load patient (to update ledger payment instantly) if order is opened from patient profile.
              this.props.dispatch(patientLoad(order.patient_id, { include: '*' }))
            }
          })
      })
    }
  }


  handleClaimFormLoad(id) {
    const { order } = this.props
    if (order.vision_insurance_claim_exist) {
      this.props.dispatch(visionInsuranceClaimFormLoad(id))
    }
    else {
      this.props.dispatch(claimFormLoad(id))
    }
  }


  handlePayOverSubmit(data) {
    const { order, orderPayment, dateFormatType } = this.props
    const endDate = new Date(addMonths(dateFormat(new Date(), dateFormatType), 1))
    const scheduleDate = new Date(data.payover_time_details.schedule_date)
    const dateInRange = isDateInRange(scheduleDate, new Date(), endDate)
    const isInstallmentValid = _.find(data.payover_time_details.installments_data, (item) => item.amount < 100)
    if (data.payover_time_details.amount > order.balance_due || data.payover_time_details.amount < 300 || !dateInRange || !Array.isArray(data.payover_time_details.installments_data) || (Array.isArray(data.payover_time_details.installments_data) && data.payover_time_details.installments_data.length == 0 || (isInstallmentValid && isInstallmentValid.length != 0))
    ) {
      // errors
      if (!dateInRange)
        Alert.warning("Date can't be in Past and Can't be more than month.")
      else if (data.payover_time_details.amount > order.balance_due)
        Alert.warning("Amount can't be greater than Balance due.")
      else if (data.payover_time_details.amount < 300)
        Alert.warning("Amount can't be less than $300.")
      else if (isInstallmentValid && isInstallmentValid.length != 0)
        Alert.warning("You are not allowed to create any installment below $100.")
      else
        Alert.warning("No Installments Present. Please try again.")
    }
    else {
      Alert.confirm(<div>Are you sure to create below mentioned installments.</div>,
        (res) => {
          if (res) {
            data = {
              id: data.orders_order_id, is_payover_time: data.is_payover_time,
              payover_time_details: { ...data.payover_time_details, payment_method_id: data.payment_method_id, customer_id: orderPayment.payment?.payment_data?.customer_id }
            }
            this.props.dispatch(orderUpdate(data, { include: '*' }))
              .then(() => $("#orderFinalizeForm-modal").modal('hide'))
          }
        }
      )
    }
  }

  handlePaymentEditCancel(data) {
    this.props.dispatch(orderPaymentEditCancel())
    $("#orderFinalizeForm-modal").modal('hide')
  }

  handleOrderSubmit(data) {
    return this.props.dispatch(orderUpdate(data, { include: '*' }))
  }

  componentDidUpdate() {
    if (!this.props.paymentEditing) {
      $("#orderFinalizeForm-modal").modal('hide')
    }
  }

  render() {
    const { paymentEditing, orderSaving, saving, order, paymentMethodSaving, devicePayment, orderPayment } = this.props;
    return (
      <div className="inner-modal refund-modal">
        <ModalFormWrapper
          formName="orderFinalizeForm"
          saving={(saving || paymentMethodSaving)}
          formTitle="Checkout"
          handleEditCancel={this.handlePaymentEditCancel.bind(this)}
          modalSize="modal-md"
          label='Pay'
          modalContentClasses="border-0 shadow rounded bg-white"
        >
          <div className="py-5 px-6 d-flex justify-content-between border-top border-top-2 bg-light position-relative">
            <h6>Order Total: {numberToCurrency(order.total)}</h6>
            <div className="d-flex ">
              {order.payover_time_details &&
                <h6 className="text-info mr-4">Payover Total: {numberToCurrency(order.payover_time_details.amount)}</h6>
              }
              <h6 className="text-danger">Balance Due: {numberToCurrency(order.balance_due)}</h6>
            </div>
          </div>
          <div className={`${orderSaving && 'disabled'} p-6`}>
            {
              paymentEditing && paymentEditing.id &&
              <PaymentForm form="orderFinalizeForm" onSubmit={this.handlePaymentSubmit.bind(this)} orderSubmit={this.handleOrderSubmit.bind(this)} paymentType={'sale'} />
            }
            {devicePayment.active && <PaymentInProgress devicePayment={devicePayment} dispatch={this.props.dispatch} deviceLog={orderPayment.itrust_pay_log} orderId={order.id} />}
          </div>
        </ModalFormWrapper>
      </div>
    );
  }
}

FinalizeButtonForm = connect(state => {
  const { orderPayment, order, patientPaymentMethod, organisation: { organisation }, setting: { orgConfig } } = state
  return {
    paymentEditing: orderPayment.editing,
    orderPayment,
    paymentMethodSaving: patientPaymentMethod.saving,
    order: order.order,
    orderSaving: order.saving,
    devicePayment: orderPayment.devicePayment,
    saving: orderPayment.saving,
    visionInsuranceConfig:organisation.add_ons_config?.type?.vision_insurance,
    dateFormatType: orgConfig.org_config?.date_format,
  }
})(FinalizeButtonForm)

export default withRouter(FinalizeButtonForm);

