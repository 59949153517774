import React from "react";
import { Route, Switch } from 'react-router-dom';
import asyncComponent from '../../../../itrust_common/components/AsyncComponent';

const DashboardWrapper = asyncComponent(() =>
  import('../../../../containers/authorised/addOns/rpa/tab/dashboard/DashboardWrapper')
)

const LogsWrapper = asyncComponent(() =>
  import('../../../../containers/authorised/addOns/rpa/tab/logs/LogsWrapper')
)

const StoreSettingWrapper = asyncComponent(() =>
  import('../../../../containers/authorised/addOns/rpa/tab/setting/store/StoreSettingWrapper')
)

const TabSettingWrapper = asyncComponent(() =>
  import('../../../../containers/authorised/addOns/rpa/tab/setting/tabSetting/TabSettingWrapper')
)

const ProcedureSettingWrapper = asyncComponent(() =>
  import('../../../../containers/authorised/addOns/rpa/tab/setting/procedure/ProcedureSettingWrapper')
)

const ProviderSettingWrapper = asyncComponent(() =>
  import('../../../../containers/authorised/addOns/rpa/tab/setting/provider/ProviderSettingWrapper')
)

const TabRoutes = (
  <Switch>
    <Route exact path='/add-ons/tab/dashboard' component={DashboardWrapper} />
    <Route exact path='/add-ons/tab/logs' component={LogsWrapper} />
    <Route exact path='/add-ons/tab/settings' component={StoreSettingWrapper} />
    <Route exact path='/add-ons/tab/settings/provider' component={ProviderSettingWrapper} />
    <Route exact path='/add-ons/tab/settings/procedure' component={ProcedureSettingWrapper} />
    <Route exact path='/add-ons/tab/settings/tab_settings' component={TabSettingWrapper} />
  </Switch>
)

export default TabRoutes;