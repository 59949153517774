import { API_VERSION_ONE, handleAPI } from "../../../../../itrust_common/utils/apiUtils";

export function visionInsuranceClaimFormCreate(data, filters) {
  const url = `/${API_VERSION_ONE}/add_ons/rpa/vision_insurance/logs`;
  const method = 'POST'
  return {
    type: 'VISION_INSURANCE_CLAIM_FORM_CREATE',
    payload: handleAPI(url, filters, method, data)
  }
}

export function visionInsuranceClaimFormLoad(orderId, filters) {
  const url = `/${API_VERSION_ONE}/add_ons/rpa/vision_insurance/logs/${orderId}/insurance_claim_form`;
  const method = 'GET'
  return {
    type: 'VISION_INSURANCE_CLAIM_FORM_LOAD',
    payload: handleAPI(url, filters, method)
  }
}

export function visionInsuranceClaimFormUpdate(data, filters) {
  const url = `/${API_VERSION_ONE}/add_ons/rpa/vision_insurance/logs/${data.id}`;
  const method = 'PUT'
  return {
    type: 'VISION_INSURANCE_CLAIM_FORM_UPDATE',
    payload: handleAPI(url, filters, method, { claim_info: { ...data } })
  }
}

export function visionInsuranceClaimFormSubmit(data, filters) {
  const url = `/${API_VERSION_ONE}/add_ons/rpa/vision_insurance/logs`;
  const method = 'POST'
  return {
    type: 'VISION_INSURANCE_CLAIM_FORM_SUBMIT',
    payload: handleAPI(url, filters, method, data)
  }
}

export function visionInsuranceClaimFormTemplateLoad(filters) {
  const url = `/${API_VERSION_ONE}/add_ons/rpa/vision_insurance/templates`;
  const method = 'GET'
  return {
    type: 'VISION_INSURANCE_CLAIM_FORM_TEMPLATE_LOAD',
    payload: handleAPI(url, filters, method)
  }
}

export function visionInsuranceClaimFormEdit(data) {
  return {
    type: 'VISION_INSURANCE_CLAIM_FORM_EDIT',
    data
  };
}

export function visionInsuranceClaimFormEditReset() {
  return {
    type: 'VISION_INSURANCE_CLAIM_FORM_EDIT_RESET',
  };
}

export function visionInsuranceClaimFormReset() {
  return {
    type: 'VISION_INSURANCE_CLAIM_FORM_RESET',
  };
}
