import React, { useEffect } from 'react'
import EyeMedFormButton from '../EyeMedFormButton'
import { AutoCompleteSelect, Checkbox, Input, Textarea } from '../../../../../../../../../../itrust_common/components/form'
import { Field, FieldArray, change, formValueSelector } from 'redux-form'
import { humanize } from '../../../../../../../../../../itrust_common/components/HelperFunctions'
import { connect, useDispatch } from 'react-redux'
import { isRequired, mergeActiveFields } from '../../../eyeMedHelper'
import { useState } from 'react'

function ExamClaimStep1(props) {
  const { claimFormTemplate, visionInsuranceClaimForm, showCpt2Fields, saving, cpt2Fields, resetForm } = props
  const dispatch = useDispatch()
  const [isDiabeticChecked, setIsDiabeticChecked] = useState([])

  useEffect(() => {
    // set checkbox keyVal from template to form
    claimFormTemplate.config.exams.exam.map((field) => field.type === "checkbox" && dispatch(change('EyeMedClaimForm', `exams.exam.${field.label}`, mergeActiveFields({ claimFormTemplate: claimFormTemplate.config.exams.exam, visionInsuranceClaimForm: visionInsuranceClaimForm.exams.exam, fieldName: field.label }))))
    // disabled diabetic
    handleDisabledDiabeticField()
  }, [visionInsuranceClaimForm.exams.exam, saving, resetForm])

  const handleDisabledDiabeticField = () => {
    let isDiabetic = visionInsuranceClaimForm.exams?.exam?.cpt_2?.find(item => item.label.includes('Diabetes'))
    if (isDiabetic) {
      handleOnChange(true, isDiabetic.label)
    }
  }

  const GetCheckbox = (props) => {
    const { field, wrapperClasses, showCpt2Fields } = props

    return (
      <div className={`${wrapperClasses}`}>
        {field.label === "cpt_2" ? <div>
          <p>
            CPT II and Disease Reporting Diagnosis <br />(If Yes, check all known conditions related to this patient)
            <Field
              name={`is_cpt2_selected`}
              component={AutoCompleteSelect}
              data={[{ label: 'Yes', value: 'yes' }, { label: 'No', value: 'no' }]}
              textField="label"
              valueField="value"
              hideSearch={true}
              wrapperClasses={`form-group mt-4 exam-auto-select`}
              validate={[...isRequired(field)]}
            />
            {showCpt2Fields == 'yes' && <FieldArray
              name="cpt_2"
              component={RenderCheckBox}
            />}
          </p>
        </div> : <>
          <label className='mb-4'>{humanize(field.label)}</label>
          <FieldArray
            name="procedure"
            component={RenderCheckBox}
          />
        </>}
      </div>
    )
  }

  const getDisabled = (label) => {
    if (isDiabeticChecked.includes(label)) {
      return true
    }
    return false
  }

  const handleOnChange = (checked, label) => {
    const diabetesVal = ['Type 1 Diabetes', 'Type 2 Diabetes', 'Unspecified Diabetes'];
    if (diabetesVal.includes(label)) {
      setIsDiabeticChecked(checked ? diabetesVal.filter(item => item !== label) : []);
    }
  };

  const RenderCheckBox = (props) => {
    const { fields } = props;
    return (
      <>
        {fields.map((member, index, members) => {
          return <Field name={`${member}.active`} label={`${humanize(members.get(index).label)}`} component={Checkbox} wrapperClasses="form-group" disabled={getDisabled(members.get(index).label)} onChange={(e) => handleOnChange(e.target.checked, members.get(index).label)} />
        })}
      </>
    )
  }

  const EyeMedExamFields = (props) => {
    const { field } = props

    switch (field.type) {
      case "dropdown":
        return <Field
          name={`${field.label}.value`}
          label={`${humanize(field.label)}`}
          component={AutoCompleteSelect}
          tooltipClasses="text-wrap"
          data={field.options}
          textField="label"
          valueField="value"
          hideSearch={true}
          wrapperClasses={`form-group mb-8 exam-auto-select ${field.label === 'exam' ? 'col-6' : 'col-3'}`}
          validate={[...isRequired(field)]}
        />

      case "checkbox":
        return <GetCheckbox field={field} showCpt2Fields={showCpt2Fields} wrapperClasses="form-group col-6" />

      case "text_area":
        return <Field name={`${field.label}`} label={`${humanize(field.label)}`} component={Textarea} className="form-control" wrapperClasses="form-group col-12" validate={[...isRequired(field)]} />

      default:
        return <Field name={`${field.label}`} label={`${humanize(field.label)}`} component={Input} className="form-control" wrapperClasses="form-group col-12" validate={[...isRequired(field)]} />
    }
  }

  return (
    <>
      <h6>Exam</h6>
      <p>Complete the information about the patient’s eye exam below. Remember, disease diagnosis codes are required.</p>
      <div className='row mt-6'>
        {claimFormTemplate.config.exams.exam?.map((field) => <EyeMedExamFields field={field} />)}
      </div>
      <EyeMedFormButton backStep={'first'} />
    </>
  )
}

const selector = formValueSelector('EyeMedClaimForm');

const mapStateToProps = (state) => {
  const { visionInsuranceClaimForm: { saving, editing: { resetForm } } } = state
  const showCpt2Fields = selector(state, 'exams.exam.is_cpt2_selected')
  // const cpt2Fields = selector(state, 'exams.exam.cpt_2')
  return {
    showCpt2Fields,
    saving,
    // cpt2Fields,
    resetForm
  }
}

export default connect(mapStateToProps)(ExamClaimStep1)