import React from 'react'
import { connect } from 'react-redux';
import ItrustPayRoutes from './itrustPayRoutes';
import TwoWayRoutes from './twoWayRoutes';
import VoipRoutes from './voipRoutes';
import EDIClearingHouseRoutes from './EDIClearingHouseRoutes';
import WhatsAppRoutes from './WhatsAppRoutes';
import VisionInsuranceRoutes from './rpa/VisionInsuranceRoutes';
import TabRoutes from './rpa/TabRoutes';

function AddOnsRoutes(props) {
  const { addOnsConfig } = props

  return (
    <>
      {addOnsConfig?.itrust_pay.visible && ItrustPayRoutes}
      {addOnsConfig?.two_way_message.visible && TwoWayRoutes}
      {addOnsConfig?.voip.visible && VoipRoutes}
      {addOnsConfig?.edi_clearing_house.visible && EDIClearingHouseRoutes}
      {addOnsConfig?.whatsapp.visible && WhatsAppRoutes}
      {/* RPA */}
      {addOnsConfig?.vision_insurance.visible && VisionInsuranceRoutes}
      {addOnsConfig?.tab.visible && TabRoutes}
    </>
  )
}

const mapStateToProps = state => {
  const { organisation: { organisation } } = state;
  return {
    addOnsConfig: organisation?.add_ons_config?.type
  }
}

export default connect(mapStateToProps)(AddOnsRoutes);