import { addOnReducer } from "./addOnReducer";
import { contactReducer } from "./contactReducer";
import { twoWayReportReducer } from "./twoWayMessaging/twoWayReportReducer";
import { EDIReportReducer } from "./EDIClearingHouse/EDIReportReducer";
import rpaReducers from "./rpa";
import whatsAppReducers from "./whatsApp";
import itrustPayReducers from "./itrustPay";
import voipReducers from "./voip";

const addOnsReducers = {
  addOn: addOnReducer,
  contact: contactReducer,
  twoWayReport: twoWayReportReducer,
  ediReport: EDIReportReducer,
  ...voipReducers,
  ...itrustPayReducers,
  ...whatsAppReducers,
  ...rpaReducers,
};

export default addOnsReducers;