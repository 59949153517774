import { handleAPI, API_VERSION_ONE } from '../../../../../itrust_common/utils/apiUtils';

export function visionInsuranceServicesLoad(filters) {
  const url = `/${API_VERSION_ONE}/add_on`;

  const method = 'GET'
  return {
    type: 'VISION_INSURANCE_SERVICES_LOAD',
    payload: handleAPI(url, filters, method)
  }
}

export function visionInsuranceServiceUpdate(data, filters) {
  const url = `/${API_VERSION_ONE}/add_on/${data.id}`;
  const method = 'PUT'
  return {
    type: 'VISION_INSURANCE_SERVICE_UPDATE',
    payload: handleAPI(url, filters, method, { add_on: data })
  }
}

export function visionInsuranceEligibilityVerify(data, filters) {
  const url = `/${API_VERSION_ONE}/add_ons/rpa/vision_insurance/logs`;
  const method = 'POST'
  return {
    type: 'VISION_INSURANCE_ELIGIBILITY_VERIFY',
    payload: handleAPI(url, filters, method, data)
  }
}

export function visionInsuranceDetailView(data) {
  return {
    type: 'VISION_INSURANCE_DETAIL_VIEW',
    data
  };
}

export function visionInsuranceEdit(data) {
  return {
    type: 'VISION_INSURANCE_EDIT',
    data
  };
}

export function visionInsuranceEditReset() {
  return {
    type: 'VISION_INSURANCE_EDIT_RESET',
  };
}

export function visionInsuranceEligibilityEdit(data) {
  return {
    type: 'VISION_INSURANCE_ELIGIBILITY_EDIT',
    data
  };
}

export function visionInsuranceEligibilityReset() {
  return {
    type: 'VISION_INSURANCE_ELIGIBILITY_RESET',
  };
}
