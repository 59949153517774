import { required } from "../../../../../../../itrust_common/components/form/FormValidations";

export const mergeActiveFields = (data, isCpt) => {
  const { claimFormTemplate, visionInsuranceClaimForm, fieldName } = data
  let template = claimFormTemplate.find(item => item.label === fieldName);
  template = isCpt ? template.cpt_codes : template.options
  const mergedOptions = template.map(templateItem => {
    let valueArr = isCpt ? visionInsuranceClaimForm?.[fieldName]?.cpt_codes : visionInsuranceClaimForm?.[fieldName]?.selected_value
    let rpaClaimFormVal = Array.isArray(visionInsuranceClaimForm[fieldName]) ? visionInsuranceClaimForm?.[fieldName] : (visionInsuranceClaimForm?.[fieldName]?.value || valueArr)
    const filledItem = visionInsuranceClaimForm?.[fieldName] ? rpaClaimFormVal?.find(item1 => item1.label === templateItem.label) : template?.find(item2 => item2.label === templateItem.label)
    return { ...templateItem, active: filledItem?.active || false, };
  });
  return mergedOptions;
}

export const isRequired = (data) => {
  return data.required ? [required] : []
}
