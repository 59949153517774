import React from 'react'
import LogsListing from '../../../../../../../../../itrust_common/components/others/listings/LogsListing'
import { Field, FieldArray } from 'redux-form'
import { Checkbox } from '../../../../../../../../../itrust_common/components/form'
import EyeMedFormButton from './EyeMedFormButton'

export default function EyeMedModuleSelect(props) {
  const { visionInsuranceClaimForm } = props

  const LogsHead = [
    { title: '' },
    { title: 'Service', className: 'text-nowrap' },
    { title: 'Member is Eligible?', className: 'text-nowrap' },
    { title: 'Member Eligible As Of', className: 'text-nowrap' },
    { title: 'Service Frequency', className: 'text-nowrap' },
  ]

  const RenderEyeMedClaimService = (props) => {
    const { fields } = props
    return (
      <>
        {fields.map((member, index, members) => <tr>
          <td>
            <Field name={`${member}[active]`} component={Checkbox} disabled={members.get(index).eligible == 'No'} wrapperClasses="pl-4" />
          </td>
          <td>{members.get(index).service_type}</td>
          <td>{members.get(index).eligible}</td>
          <td>{members.get(index).next_date}</td>
          <td>{members.get(index).frequency}</td>
        </tr>)}
      </>
    )
  }

  return (
    <LogsListing logs={visionInsuranceClaimForm.eligibility.data} TableHead={LogsHead}>
      <FieldArray name="eligibility.data" props={{ visionInsuranceClaimForm }} component={RenderEyeMedClaimService} />
      <tr>
        <td colSpan={10}>
          <EyeMedFormButton buttonClasses="py-2" />
        </td>
      </tr>
    </LogsListing>
  )
}
