import { visionInsuranceClaimFormReducer } from "./visionInsuranceClaimFormReducer";
import { visionInsuranceReducer } from "./visionInsuranceReducer";
import { visionInsuranceReportReducer } from "./visionInsuranceReportReducer";

// const visionInsuranceReducers = {
//   rpa: visionInsuranceReducer,
//   rpaReport: visionInsuranceReportReducer,
//   rpaClaimForm: visionInsuranceClaimFormReducer,
// };

const visionInsuranceReducers = {
  visionInsurance: visionInsuranceReducer,
  visionInsuranceReport: visionInsuranceReportReducer,
  visionInsuranceClaimForm: visionInsuranceClaimFormReducer,
};
export default visionInsuranceReducers;