import React, { useEffect } from 'react'
import EyeMedClaimForm from '../../services/eyemed/claimForm/form/EyeMedClaimForm';
import { connect, useDispatch } from 'react-redux';
import { visionInsuranceClaimFormEdit, visionInsuranceClaimFormTemplateLoad } from '../../../../../../../redux/actions/addOns/rpa/vision_insurance/visionInsuranceClaimFormAction';

function RpaClaimForm(props) {
  const { order, visionInsuranceClaimFormTemplate } = props
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(visionInsuranceClaimFormTemplateLoad())
    dispatch(visionInsuranceClaimFormEdit({ activeTab: 'first', activeModule: '' }))
  }, [])

  const getClaimFormTemplate = () => {
    // const service = services.find((item) => item.config.insurance_payer == order.patient_insurance_attributes?.insurance_name)
    return visionInsuranceClaimFormTemplate.find(item => item.service == order.vision_insurance_service?.service)
  }

  // const getServiceName = (serviceName) => {
  //   const service = services.find((item) => item.config.service == serviceName)
  //   return service.config.insurance_payer
  // }

  switch (order.vision_insurance_service?.service) {
    case 'eyemed':
      return <EyeMedClaimForm claimFormTemplate={getClaimFormTemplate()} />

    default:
      return ''
  }
}

const mapStateToProps = (state) => {
  const { order: { order }, visionInsuranceClaimForm: { visionInsuranceClaimFormTemplate } } = state
  return {
    // services,
    order,
    visionInsuranceClaimFormTemplate,
  }
}

export default connect(mapStateToProps)(RpaClaimForm);