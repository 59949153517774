import React from 'react'
import { dateTimeFormat } from '../../../../../../itrust_common/components/HelperFunctions'
import { visionInsuranceDetailView } from '../../../../../../redux/actions/addOns/rpa/vision_insurance/visionInsuranceAction'
import DateFormatView from '../../../../../../itrust_common/components/others/DateFormatView'

export default function RPASubmitHistoryListing(props) {
  const { expired_vision_insurance_logs, dispatch, wrapperClasses } = props

  return (
    <table className={`table shadow-none mb-0 ${wrapperClasses}`}>
      <thead className="">
        <tr>
          <th>Date and Time</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {expired_vision_insurance_logs?.length ? expired_vision_insurance_logs?.map((item) => <tr className='pointer' onClick={() => item.status === 'found' && dispatch(visionInsuranceDetailView({ visible: true, file_url: item.file, service: item.service }))}>
          <td><DateFormatView dateTime={item.timestamp} /></td>
          <td className='vision-insurance-log'>
            {item.status === 'found' ?
              <span className="badge rounded bg-success">Found</span> :
              <span className="badge rounded bg-failed custom-tooltip " data-title={item.error_message}>Not Found
                <i className="las la-info-circle ml-3"></i>
              </span>
            }
          </td>
          <td>
            {item.status === 'found' ?
              <span className='text-primary font-size-24 mr-3'>
                <i className="las la-eye"></i>
              </span> :
              <span className='text-danger font-size-24 mr-3'>
                <i className="las la-eye-slash"></i>
              </span>
            }
          </td>
        </tr>) :
          <tr className='text-center py-3'>
            <td colSpan={10}>Not Found</td>
          </tr>
        }
      </tbody>
    </table>
  )
}
