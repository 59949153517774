import React from "react";
import { Route, Switch } from 'react-router-dom';
import asyncComponent from '../../../../itrust_common/components/AsyncComponent';

const DashboardWrapper = asyncComponent(() =>
  import('../../../../containers/authorised/addOns/rpa/vision_insurance/dashboard/DashboardWrapper')
)

const ClaimSubmissionLogs = asyncComponent(() =>
  import('../../../../containers/authorised/addOns/rpa/vision_insurance/logs/claimSubmission/LogsWrapper')
)

const EligibilityLogs = asyncComponent(() =>
  import('../../../../containers/authorised/addOns/rpa/vision_insurance/logs/eligibility/LogsWrapper')
)

const SettingsWrapper = asyncComponent(() =>
  import('../../../../containers/authorised/addOns/rpa/vision_insurance/settings/SettingsWrapper')
)

const VisionInsuranceRoutes = (
  <Switch>
    <Route exact path='/add-ons/vision-insurance/dashboard' component={DashboardWrapper} />
    <Route exact path='/add-ons/vision-insurance/eligibility-logs' component={EligibilityLogs} />
    <Route exact path='/add-ons/vision-insurance/claim-submission-logs' component={ClaimSubmissionLogs} />
    <Route exact path='/add-ons/vision-insurance/settings' component={SettingsWrapper} />
  </Switch>
)

export default VisionInsuranceRoutes;