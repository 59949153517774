import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { submit } from 'redux-form'
import * as $ from 'jquery';
import Alert from '../../../../../../../itrust_common/components/Alert';
import { getInsuranceFromRPAServices } from '../../../../other/AddOnsHelperFunctions';
import { visionInsuranceServicesLoad } from '../../../../../../../redux/actions/addOns/rpa/vision_insurance/visionInsuranceAction';
import { visionInsuranceClaimFormSubmit } from '../../../../../../../redux/actions/addOns/rpa/vision_insurance/visionInsuranceClaimFormAction';

function RpaClaimSubmitButton(props) {
  const { rpaSubmitting, order, visionInsuranceConfig, services, visionInsuranceClaimForm } = props
  const history = useHistory()
  const dispatch = useDispatch()

  useEffect(() => {
    let storeID = localStorage.getItem('StoreID')
    if (storeID == null) {
      // Store dropdown All selected > load RPA services from order's store_id
      dispatch(visionInsuranceServicesLoad({ store_id: order.store_id, category: "vision_insurance" }))
    }
  }, [])

  const handleRpaClaimSubmit = () => {
    if (visionInsuranceConfig?.status !== 'inactive') {
      if (visionInsuranceClaimForm.ready_to_submit || order.vision_insurance_service?.service == '2020source') {
        submitClaim()
      }
      else {
        Alert.warning("Your claim form isn't ready to submit yet. Please complete the form filling process.")
      }
    }
    else {
      history.push('/add-ons/vision-insurance/dashboard')
      $('#orderForm-modal').modal('hide');
    }
  }

  const submitClaim = () => {
    const insurance = getInsuranceFromRPAServices(services, order.patient_insurance_attributes?.insurance_name)
    dispatch(submit("1500ClamForm"))
    setTimeout(() => {
      let invalidInputs = (document.getElementsByClassName("is-invalid")?.length || document.getElementsByClassName("invalid-feedback")?.length)
      if (invalidInputs) {
        Alert.warning("Please fill the required information.")
      } else {
        dispatch(visionInsuranceClaimFormSubmit({ add_ons_id: insurance.id, orders_order_id: order.id, patient_id: order.patient_id, insurance_type: order.patient_insurance_attributes?.insurance_type, store_id: order.store_id, insurance_payer: order.patient_insurance_attributes?.insurance_name, service: insurance.config.service, other_details: { resource: "submit_claim" } }))
      }
    }, 500)
  }

  return (
    <>
      <button className="btn btn-outline-primary mr-lg-7 mr-md-5 mx-md-4 my-md-2 mx-lg-0 my-lg-0" onClick={handleRpaClaimSubmit}>
        {rpaSubmitting ? 'Sending...' : `Send to ${order.patient_insurance_attributes?.insurance_name}`}
      </button>
    </>
  )
}
const mapStateToProps = (state) => {
  const { organisation: { organisation }, visionInsurance: { services }, visionInsuranceClaimForm: { visionInsuranceClaimForm, submitting } } = state

  return {
    rpaSubmitting: submitting,
    visionInsuranceConfig:organisation.add_ons_config?.type?.vision_insurance,
    services,
    visionInsuranceClaimForm,
  }
}

export default connect(mapStateToProps)(RpaClaimSubmitButton)
