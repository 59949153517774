import React, { useEffect } from 'react'
import Calling from './voip/Calling'
import VoipStatusNotification from './voip/VoipStatusNotification'
import ItrustPayNotification from './itrustPay/ItrustPayNotification'
import { connect, useDispatch } from 'react-redux'
import WhatsAppPopupWrap from './whatsApp/patient/WhatsAppPopupWrap'
import { callPopUpEdit, voipAddonLoad } from '../../../redux/actions/addOns/voip/voipActions'
import { whatsAppEdit, whatsAppProfileLoad } from '../../../redux/actions/addOns/whatsApp/whatsAppActions'
import WhatsAppNotification from './whatsApp/WhatsAppNotification'
import { tabAddonLoad } from '../../../redux/actions/addOns/rpa/tab/tabAction'
import { visionInsuranceServicesLoad } from '../../../redux/actions/addOns/rpa/vision_insurance/visionInsuranceAction'
import VerifiedInfoView from './rpa/vision_insurance/eligibilityVerify/VerifiedInfoView'

function AddOns(props) {
  const { itrustPayConfig, voipConfig, whatsAppConfig, userType, whatsAppPatientProfile, rpaDetailViewPopup, visionInsuranceConfig, tabConfig } = props
  const dispatch = useDispatch()

  useEffect(() => {
    handleRpaServicesLoad()
  }, [visionInsuranceConfig])

  useEffect(() => {
    handleTabAddonLoad()
  }, [tabConfig])

  useEffect(() => {
    // get voip settings obj
    let storeID = localStorage.getItem('StoreID')
    if (storeID) {
      dispatch(voipAddonLoad({ category: "voip" }))
    }
  }, [voipConfig])

  const handleRpaServicesLoad = async () => {
    if (visionInsuranceConfig?.status && visionInsuranceConfig?.status !== 'inactive') {
      let storeID = localStorage.getItem('StoreID')
      if (storeID) {
        dispatch(visionInsuranceServicesLoad({ store_id: storeID, category: "vision_insurance" }))
      }
    }
  }

  const handleTabAddonLoad = async () => {
    if (tabConfig?.status && tabConfig?.status !== 'inactive') {
      let storeID = localStorage.getItem('StoreID')
      if (storeID) {
        dispatch(tabAddonLoad({ store_id: storeID, category: "tab" }))
      }
    }
  }

  useEffect(() => {
    let storeID = localStorage.getItem('StoreID')
    whatsAppConfig?.status && whatsAppConfig?.status !== 'inactive' && storeID && dispatch(whatsAppProfileLoad({ store_id: storeID, category: "whatsapp", include: '*' }))
  }, [whatsAppConfig])

  useEffect(() => {
    if (!userType) {
      // On Logout Reset PopUps
      dispatch(callPopUpEdit(''))
      dispatch(whatsAppEdit({ patientProfile: false }))
    }
  }, [userType])

  return (
    <>
      <Calling />
      {userType !== 'Patient' && <>
        {(voipConfig?.visible && voipConfig?.status !== 'inactive') && <>
          <audio loop autoPlay id='bandwidth-ringtone' className='d-none'></audio>
          <VoipStatusNotification />
        </>}
        {(itrustPayConfig?.visible && itrustPayConfig?.status !== 'inactive') && <ItrustPayNotification />}
        {(whatsAppConfig?.visible && whatsAppConfig?.status !== 'inactive') && <WhatsAppNotification />}
      </>}
      {whatsAppPatientProfile && <WhatsAppPopupWrap />}
      {visionInsuranceConfig?.visible && rpaDetailViewPopup.visible && <VerifiedInfoView rpaDetailViewPopup={rpaDetailViewPopup} />}
    </>
  )
}

const mapStateToProps = (state) => {
  const { organisation: { organisation }, auth: { user }, whatsApp, visionInsurance } = state
  return {
    itrustPayConfig: organisation.add_ons_config?.type?.itrust_pay,
    whatsAppConfig: organisation.add_ons_config?.type?.whatsapp,
    voipConfig: organisation.add_ons_config?.type?.voip,
    userType: user?.type,
    whatsAppPatientProfile: whatsApp.patientProfile,
    rpaDetailViewPopup: visionInsurance.detailViewPopup,
    visionInsuranceConfig:organisation.add_ons_config?.type?.vision_insurance,
    tabConfig: organisation.add_ons_config?.type?.tab,
  }
}

export default connect(mapStateToProps)(AddOns)