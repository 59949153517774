import React from 'react'
import RpaClaimGenerateButton from '../../../claimForm/generateClaim/RpaClaimGenerateButton'
import { visionInsuranceClaimFormTemplateLoad } from '../../../../../../../../redux/actions/addOns/rpa/vision_insurance/visionInsuranceClaimFormAction'

function EyeMedClaimGenerate(props) {
  const { pprops: { rpaClaimFormGenerate, dispatch } } = props

  const rpaEyeMedClaimGenerate = async () => {
    await rpaClaimFormGenerate()
    await dispatch(visionInsuranceClaimFormTemplateLoad())
  }

  return (
    <>
      <RpaClaimGenerateButton rpaClaimFormGenerate={rpaEyeMedClaimGenerate} />
    </>
  )
}

export default EyeMedClaimGenerate;