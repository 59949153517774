import React, { Fragment } from 'react'

export default function LogsListing(props) {
  const { logs, TableBody, TableHead, wrapperClasses, tableClasses } = props

  return (
    <div className={`overview-table ${wrapperClasses}`}>
      <table className={`table table-hover bg-white ${tableClasses}`}>
        <thead className="text-dark border-bottom border-light border-bottom-2">
          <tr>
            {TableHead.map((head) => <th scope="col" className={`${head.className}`} {...head.attribute}>
              {head.Component ?
                <head.Component /> :
                head.title
              }
            </th>)}
          </tr>
        </thead>
        <tbody>
          {TableBody ?
            <>
              {logs.length ?
                logs.map((log, index) => <Fragment key={log.id}><TableBody log={log} index={index} /></Fragment>) :
                <tr>
                  <td colSpan={14} className='text-center font-size-18'>No Record Found</td>
                </tr>
              }
            </> :
            <>{props.children}</>
          }
        </tbody>
      </table>
    </div>
  )
}
