import React from 'react'
import EyeMedModuleSelect from './EyeMedModuleSelect'
import ExamClaimForm from './exam/ExamClaimForm'
import EyeMedFinalStep from './EyeMedFinalStep'
import ContactLensClaim from './contactLens/ContactLensClaim'
import { FormSection } from 'redux-form'

export default function EyeMedStepForm(props) {
  const { pprops: { visionInsuranceClaimForm, claimFormTemplate, saving, editing, editing: { activeTab, activeModule, } } } = props

  return (
    <div className='mt-7'>
      {activeTab === 'first' && <EyeMedModuleSelect visionInsuranceClaimForm={visionInsuranceClaimForm} />}
      {activeModule === 'Contact Lens Fit and Follow-up' && <FormSection name='contact_lens_fit_and_follow_up'>
        <ContactLensClaim activeTab={activeTab} claimFormTemplate={claimFormTemplate} />
      </FormSection>}
      {activeModule === 'Exam' && <FormSection name='exams'>
        <ExamClaimForm activeTab={activeTab} claimFormTemplate={claimFormTemplate} saving={saving} editing={editing} visionInsuranceClaimForm={visionInsuranceClaimForm} />
      </FormSection>}
      {activeTab === 'final' && <EyeMedFinalStep saving={saving} editing={editing} visionInsuranceClaimForm={visionInsuranceClaimForm} />}
    </div>
  )
}
