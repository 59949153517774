import React from 'react'
import { connect, useDispatch } from 'react-redux';
import Alert from '../../../../../../../itrust_common/components/Alert';
import RPAVerifyButton from '../../eligibilityVerify/RPAVerifyButton';
import { visionInsuranceDetailView, visionInsuranceEligibilityEdit } from '../../../../../../../redux/actions/addOns/rpa/vision_insurance/visionInsuranceAction';

function EyeMedVerify(props) {
  const { pprops, pprops: { selectedInsuranceName, selectedInsuranceMemberId, orderId, patient, insuranceType, handleInsuranceVerifySubmit, setShowVerifyBtn } } = props
  const dispatch = useDispatch()

  const handleVerifyInsurance = async () => {
    const insuranceRequiredInfo = (selectedInsuranceName && selectedInsuranceMemberId)
    if (insuranceRequiredInfo) {
      let data = { member_id: selectedInsuranceMemberId }
      handleInsuranceVerifySubmit(data)
    }
    else {
      Alert.warning(<div>
        <span>Please make sure to Add and Save the following required Patient Information in Patient Profile to verify the Insurance:</span>
        <ul className='pl-6'>
          <li>{`${orderId ? 'Insurance Payer' : 'Insurance'}`}</li>
          <li>Insurance MemberID</li>
        </ul>
      </div>
      )
    }
  }

  const handleReVerifyInsurance = () => {
    Alert.confirm('Are you sure you want to Re-Verify Insurance.', res => {
      if (res) {
        dispatch(visionInsuranceDetailView({ visible: false }))
        setShowVerifyBtn(true)
        dispatch(visionInsuranceEligibilityEdit({ [insuranceType]: null }))
        handleVerifyInsurance()
      }
    })
  }

  return (
    <>
      <RPAVerifyButton
        pprops={pprops}
        handleVerifyInsurance={handleVerifyInsurance}
        handleReVerifyInsurance={handleReVerifyInsurance}
      />
    </>
  )
}


export default connect()(EyeMedVerify)