import React from 'react'
import { useDispatch } from 'react-redux'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as $ from 'jquery';
import { visionInsuranceDetailView } from '../../../../../../redux/actions/addOns/rpa/vision_insurance/visionInsuranceAction';

function RPAVerifyButton(props) {
  const { visionInsuranceConfig, handleVerifyInsurance, handleReVerifyInsurance, rpaEligibility, verify, pprops: { btnClasses, insuranceType, wrapperClasses, orderId, patient, order, insuranceVerifying, showVerifyBtn, isOrderView } } = props
  const dispatch = useDispatch()
  const history = useHistory()

  const handleInactiveVerify = () => {
    history.push('/add-ons/vision-insurance/dashboard')
    $('#orderForm-modal').modal('hide');
  }

  return (
    <>
      <div className={`d-flex ${wrapperClasses}`}>
        <div className={``}>
          {visionInsuranceConfig?.status !== 'inactive' ?
            <>
              {rpaEligibility[insuranceType]?.success === undefined && <>
                {insuranceVerifying === insuranceType ?
                  <button className={`btn btn-primary btn-sm ${btnClasses} text-nowrap`} type='button'>
                    <span>Verifying...</span>
                    <div className="spinner-border verify-spinner ml-2" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </button> :
                  <>
                    {verify ?
                      <button className={`btn btn-primary btn-sm ${btnClasses}`} type='button' disabled={true}>
                        Verify
                      </button> :
                      <button className={`btn btn-primary btn-sm ${btnClasses}`} type='button' onClick={handleVerifyInsurance}>
                        Verify
                      </button>
                    }
                  </>
                }
              </>}
              {rpaEligibility[insuranceType] && <>
                <div className='text-nowrap'>
                  {showVerifyBtn && <>
                    {rpaEligibility[insuranceType]?.success ?
                      <>
                        <button type='button' className={`text-nowrap btn btn-success btn-sm ${btnClasses}`} onClick={() => dispatch(visionInsuranceDetailView({ visible: true, file_url: rpaEligibility[insuranceType]?.eligibility_file_url, service: rpaEligibility[insuranceType]?.service }))}>
                          Found <i className="las la-check-circle"></i>
                        </button>
                      </> :
                      <button type='button' className={`text-nowrap btn btn-danger custom-tooltip btn-sm ${btnClasses}`} data-title={`Error: ${rpaEligibility[insuranceType].error}`}>
                        Not Found <i className="las la-times-circle"></i>
                      </button>
                    }
                  </>}
                  {!showVerifyBtn && <button type='button' className={`btn btn-primary d-flex align-items-center text-nowrap ml-2 btn-sm ${btnClasses}`} title='Retry' disabled={verify} onClick={handleReVerifyInsurance}>
                    Re-Verify <i className="la la-sync font-size-14 ml-2" />
                  </button>}
                </div>
              </>}
            </> :
            <button type='button' className={`btn btn-primary btn-sm ${btnClasses}`} onClick={handleInactiveVerify}>
              Verify
            </button>
          }
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  const { visionInsurance, organisation: { organisation } } = state

  return {
    rpaEligibility: visionInsurance.eligibility,
    verify: visionInsurance.verify,
    visionInsuranceConfig: organisation.add_ons_config?.type?.vision_insurance
  }
}

export default connect(mapStateToProps)(RPAVerifyButton)