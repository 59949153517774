import React, { useEffect } from 'react'
import { claimFormSync } from '../../../../redux/actions/order/claimFormActions'
import { connect, useDispatch } from 'react-redux'
import { getInsuranceFromRPAServices } from '../../../../containers/authorised/addOns/other/AddOnsHelperFunctions'
import { visionInsuranceServicesLoad } from '../../../../redux/actions/addOns/rpa/vision_insurance/visionInsuranceAction'
import { visionInsuranceClaimFormCreate, visionInsuranceClaimFormLoad } from '../../../../redux/actions/addOns/rpa/vision_insurance/visionInsuranceClaimFormAction'

function ClaimFormSync(props) {
  const { order, label, handleAfterSyncSuccessfully, services } = props
  const dispatch = useDispatch()

  useEffect(() => {
    let storeID = localStorage.getItem('StoreID')
    if (storeID == null) {
      // Store dropdown All selected > load RPA services from order's store_id
      dispatch(visionInsuranceServicesLoad({ store_id: order.store_id, category: "vision_insurance" }))
    }
  }, [])

  const handleClaimFormSync = async () => {
    if (order.vision_insurance_claim_exist) {
      const insurance = getInsuranceFromRPAServices(services, order.patient_insurance_attributes?.insurance_name)
      let rpaClaimGenerateData = { sync_claim_form: true, add_ons_id: insurance.id, orders_order_id: order.id, patient_id: order.patient_id, insurance_type: order.patient_insurance_attributes?.insurance_type, store_id: order.store_id, insurance_payer: order.patient_insurance_attributes?.insurance_name, service: insurance.config.service, other_details: { resource: "generate_claim" } }
      await dispatch(visionInsuranceClaimFormCreate(rpaClaimGenerateData))
      await dispatch(visionInsuranceClaimFormLoad(order.id))
      handleAfterSyncSuccessfully && handleAfterSyncSuccessfully()
    }
    else {
      dispatch(claimFormSync(order.id))
    }
  }

  return (
    <>
      <button type="button" className="btn btn-outline-primary ml-md-5 my-md-2 my-lg-0" onClick={handleClaimFormSync}>{label ?? 'Sync Data'}</button>
    </>
  )
}

const mapStateToProps = (state) => {
  const { order: { order }, visionInsurance: { services } } = state
  return {
    order,
    services
  }
}

export default connect(mapStateToProps)(ClaimFormSync)