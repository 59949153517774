import Toastr from "../../../../../itrust_common/components/Toastr";

const DEFAULT_STATE = {
  saving: false,
  submitting: false,
  editing: {},
  visionInsuranceClaimForm: {},
  visionInsuranceClaimFormTemplate: []
}

let changes = null

export const visionInsuranceClaimFormReducer = (state = DEFAULT_STATE, action) => {
  const response = action.payload && (action.payload.data || action.payload.response)

  switch (action.type) {

    case 'VISION_INSURANCE_CLAIM_FORM_CREATE_PENDING':
    case 'VISION_INSURANCE_CLAIM_FORM_UPDATE_PENDING':
      return { ...state, saving: true };

    case 'VISION_INSURANCE_CLAIM_FORM_SUBMIT_PENDING':
      return { ...state, submitting: true };

    case 'VISION_INSURANCE_CLAIM_FORM_SUBMIT_REJECTED':
    case 'VISION_INSURANCE_CLAIM_FORM_CREATE_REJECTED':
    case 'VISION_INSURANCE_CLAIM_FORM_LOAD_REJECTED':
    case 'VISION_INSURANCE_CLAIM_FORM_UPDATE_REJECTED':
    case 'VISION_INSURANCE_CLAIM_FORM_TEMPLATE_LOAD_REJECTED':
      Toastr.handleShow(response.data.success, response.data.error, response.data.status_code)
      changes = { saving: false, submitting: false }
      return { ...state, ...changes };

    case 'VISION_INSURANCE_CLAIM_FORM_SUBMIT_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      changes = { saving: false, submitting: false }
      return { ...state, ...changes };

    case 'VISION_INSURANCE_CLAIM_FORM_CREATE_FULFILLED':
      Toastr.handleShow(true, response.meta.message, 201)
      changes = { meta: { ...response.meta }, saving: false }
      return { ...state, ...changes };

    case 'VISION_INSURANCE_CLAIM_FORM_LOAD_FULFILLED':
      changes = { visionInsuranceClaimForm: { ...response.insurance_claim_form }, saving: false }
      return { ...state, ...changes };

    case 'VISION_INSURANCE_CLAIM_FORM_UPDATE_FULFILLED':
      Toastr.handleShow(true, response.message, 201)
      changes = { visionInsuranceClaimForm: { ...response.insurance_claim_form }, saving: false }
      return { ...state, ...changes };

    case 'VISION_INSURANCE_CLAIM_FORM_TEMPLATE_LOAD_FULFILLED':
      changes = { visionInsuranceClaimFormTemplate: response['add_ons/rpa/vision_insurance/templates'], saving: false }
      return { ...state, ...changes };

    case 'VISION_INSURANCE_CLAIM_FORM_EDIT':
      changes = { editing: { ...state.editing, ...action.data } }
      return { ...state, ...changes };

    case 'VISION_INSURANCE_CLAIM_FORM_EDIT_RESET':
      changes = { editing: {} }
      return { ...state, ...changes };

    case 'VISION_INSURANCE_CLAIM_FORM_RESET':
      changes = { visionInsuranceClaimForm: {} }
      return { ...state, ...changes };

    default:
      return state
  }
}