import { API_VERSION_ONE, handleAPI } from "../../../../../itrust_common/utils/apiUtils";

export function tabAddonLoad(filters) {
  const url = `/${API_VERSION_ONE}/add_on`;
  const method = 'GET'
  return {
    type: 'TAB_ADD_ON_LOAD',
    payload: handleAPI(url, filters, method)
  }
}

export function tabAddonUpdate(data, filters) {
  const url = `/${API_VERSION_ONE}/add_on/${data.id}`;
  const method = 'PUT'
  return {
    type: 'TAB_ADD_ON_UPDATE',
    payload: handleAPI(url, filters, method, { add_on: data })
  }
}

export function tabSettingsLoad(filters) {
  const url = `/${API_VERSION_ONE}/add_ons/rpa/tab/settings`;
  const method = 'GET'
  return {
    type: 'TAB_SETTINGS_LOAD',
    payload: handleAPI(url, filters, method)
  }
}

export function tabSettingUpdate(data, filters) {
  const url = `/${API_VERSION_ONE}/add_ons/rpa/tab/settings/${data.id}`;
  const method = 'PUT'
  return {
    type: 'TAB_SETTING_UPDATE',
    payload: handleAPI(url, filters, method, data)
  }
}

export function tabEdit(data) {
  return {
    type: 'TAB_EDIT',
    data
  };
}

export function tabEditReset() {
  return {
    type: 'TAB_EDIT_RESET',
  };
}